<template>
  <div id="data-list-thumb-view" class="data-list-container">
    <div class="search-container">
      <div class="left-filter">
        <v-select @input="onFilterChanged" class="filter-dd" placeholder="Filter" multiple v-model="accountsFilter" :options="optionsPaymentStatus"> </v-select>
      </div>
      <div class="right-search">
        <v-select @input="onSearchUpdated" class="filter-dd" placeholder="Search" v-model="searchFilter" :options="searchOptions"> </v-select>
      </div>
    </div>

    <vs-table ref="table" v-model="selected" pagination :max-items="itemsPerPage" search :data="filteredAccounts">
      <template slot="thead">
        <vs-th sort-key="name">
          {{ $t('vue.name') }}
        </vs-th>

        <vs-th>
          {{ $t('vue.status') }}
        </vs-th>

        <vs-th>
          {{ $t('vue.expiryAfterDays') }}
        </vs-th>

        <vs-th>
          {{ $t('vue.widgetLastUpdated') }}
        </vs-th>

        <vs-th>
          {{ $t('vue.analysedVisitors') }}
        </vs-th>

        <vs-th>
          {{ $t('vue.measuredVisitors') }}
        </vs-th>

        <vs-th>
          {{ $t('views.home.totalWebsiteVisitors') }}
        </vs-th>

        <vs-th>{{ $t('vue.action') }}</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.name">
              <div class="flex flex-column items-start">
                <div v-if="tr.isShopifyAccount">
                  <div><shopify-icon :width="60" :height="17"></shopify-icon></div>
                </div>
                <div v-else-if="tr.isAppSumoAccount">
                  <div><AppSumoLogoDark :width="60" :height="10" /></div>
                </div>
                <div>{{ tr.name }} - {{ tr.id }}</div>

                <div>
                  <vs-chip class="chip-large" transparent color="success">
                    {{ tr.created }}
                  </vs-chip>
                </div>
              </div>
            </vs-td>

            <vs-td>
              <vs-chip class="chip-large" v-if="getAccountStatus(tr) === 'on-trial'" transparent color="warning">
                {{ getAccountStatus(tr) }}
              </vs-chip>
              <vs-chip class="chip-large" v-else-if="getAccountStatus(tr).indexOf('FREE') > -1" transparent color="danger">
                {{ getAccountStatus(tr) }}
              </vs-chip>
              <vs-chip class="chip-large" v-else transparent color="success">
                {{ getAccountStatus(tr) }}
              </vs-chip>
            </vs-td>

            <vs-td>
              <vs-chip class="chip-large" transparent color="primary">
                {{ getExpireAfterDays(tr) }}
              </vs-chip>
            </vs-td>

            <vs-td>
              <vs-chip class="chip-large" v-if="tr.lastWidgetUpdated" transparent color="primary">
                {{ tr.lastWidgetUpdated }}
              </vs-chip>
            </vs-td>

            <vs-td>
              <vs-chip class="chip-large" transparent color="primary">
                ({{ tr.visitors.previousMonth.totalVisitors }}) {{ tr.visitors.currentMonth.totalVisitors }}
              </vs-chip>

              <vs-chip
                class="chip-large"
                v-if="tr.visitors.currentMonth.averageVisitors > tr.visitors.previousMonth.averageVisitors"
                transparent
                color="success"
              >
                <vs-icon class="icon-large" icon="trending_up"></vs-icon>
              </vs-chip>
              <vs-chip
                class="chip-large"
                v-if="tr.visitors.currentMonth.averageVisitors < tr.visitors.previousMonth.averageVisitors"
                transparent
                color="danger"
              >
                <vs-icon class="icon-large" icon="trending_down"></vs-icon>
              </vs-chip>
            </vs-td>

            <vs-td>
              <vs-chip class="chip-large" transparent color="primary">
                ({{ tr.measuredVisitors.previousMonth.totalVisitors }}) {{ tr.measuredVisitors.currentMonth.totalVisitors }}
              </vs-chip>

              <vs-chip
                class="chip-large"
                v-if="tr.measuredVisitors.currentMonth.averageVisitors > tr.measuredVisitors.previousMonth.averageVisitors"
                transparent
                color="success"
              >
                <vs-icon class="icon-large" icon="trending_up"></vs-icon>
              </vs-chip>
              <vs-chip v-if="tr.measuredVisitors.currentMonth.averageVisitors < tr.measuredVisitors.previousMonth.averageVisitors" transparent color="danger">
                <vs-icon class="icon-large" icon="trending_down"></vs-icon>
              </vs-chip>
            </vs-td>

            <vs-td>
              <vs-chip class="chip-large" transparent color="primary">
                ({{ tr.totalVisitors.previousMonth.totalVisitors }}) {{ tr.totalVisitors.currentMonth.totalVisitors }}
              </vs-chip>

              <vs-chip
                class="chip-large"
                v-if="tr.totalVisitors.currentMonth.averageVisitors > tr.totalVisitors.previousMonth.averageVisitors"
                transparent
                color="success"
              >
                <vs-icon class="icon-large" icon="trending_up"></vs-icon>
              </vs-chip>
              <vs-chip
                class="chip-large"
                v-if="tr.totalVisitors.currentMonth.averageVisitors < tr.totalVisitors.previousMonth.averageVisitors"
                transparent
                color="danger"
              >
                <vs-icon class="icon-large" icon="trending_down"></vs-icon>
              </vs-chip>
            </vs-td>

            <vs-td class="whitespace-no-wrap">
              <div class="flex">
                <div class="ml-2">
                  <vx-tooltip :text="$t('vue.impersonate')" position="top">
                    <vs-button
                      v-if="activeUserInfo.uid !== tr.createdBy"
                      color="primary"
                      type="border"
                      size="medium"
                      icon-pack="feather"
                      icon="icon-user"
                      @click.stop="impersonate(tr.createdBy)"
                    ></vs-button>
                  </vx-tooltip>
                </div>
              </div>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>

    <vs-button @click="onPreviousPage" :disabled="currentPage === 1">Previous</vs-button>
    <vs-button class="ml-2" @click="onNextPage" :disabled="isNextPageDisabled">Next</vs-button>
  </div>
</template>
<script>
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'

const dayjs = require('dayjs')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

import ShopifyIcon from '@/components/icons/ShopifyIcon.vue'
import AppSumoLogoDark from '@/components/icons/appsumo/AppSumoLogoDark.vue'

import 'vue-multiselect/dist/vue-multiselect.min.css'
import vSelect from 'vue-select'

export default {
  components: {
    ShopifyIcon,
    AppSumoLogoDark,
    vSelect
  },
  data() {
    return {
      debouncedUpdateLocalStorage: null,

      isMounted: false,
      selected: [],
      accounts: [],
      limit: 40,
      itemsPerPage: 40,
      lastVisible: null, // Track the last document for pagination
      firstVisible: null, // Track the first document for previous page
      currentPage: 0, // Track the current page
      pages: [], // Store the start and end documents of each page,
      maxPagesVisited: 0,
      accountsFilter: [],
      searchFilter: null,
      isDisabledNextPage: false,
      optionsPaymentStatus: [
        { value: 'on-trial', label: 'On Trial' },
        { value: 'free', label: 'FREE' },
        { value: 'letsconnect', label: "Let's Connect" },
        { value: 'appsumo', label: 'AppSumo' },
        { value: 'shopify', label: 'Shopify' },
        { value: 'vega-trial', label: 'Vega Trial' },
        { value: 'vega', label: 'Vega' }
      ],
      searchOptions: [
        { value: 'on-trial', label: 'Trial Users' },
        { value: 'letsconnect', label: "Let's Connect subscribers" },
        { value: 'appsumo', label: 'AppSumo' },
        { value: 'shopify', label: 'Shopify' },
        { value: 'free', label: 'FREE' },
        { value: 'vega', label: 'VEGA' }
      ]
    }
  },
  computed: {
    isNextPageDisabled() {
      return this.accounts.length < this.itemsPerPage || this.isDisabledNextPage
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    locale() {
      return this.$i18n.locale
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.accounts.length
    },
    filteredAccounts() {
      if (!Array.isArray(this.accountsFilter) || this.accountsFilter.length === 0) {
        return this.accounts
      }
      const filterValues = this.accountsFilter.map((filter) => filter.value)

      const filterFunctions = {
        'on-trial': (account) => account.paymentStatus && account.paymentStatus.toLowerCase() === 'on-trial',
        free: (account) => account.planType && account.planType.toLowerCase() === 'free',
        letsconnect: (account) =>
          // eslint-disable-next-line
          account.paymentStatus &&
          account.paymentStatus.toLowerCase() === 'subscription' &&
          ['M_BASIC_ANNUAL', 'M_BASIC_MONTHLY', 'M_PROFESSIONAL_MONTHLY', 'M_PROFESSIONAL_ANNUAL', 'M_ENTERPRISE_MONTHLY', 'M_ENTERPRISE_ANNUAL'].includes(
            account.subscriptionPlanId
          ),
        appsumo: (account) =>
          // eslint-disable-next-line
          account.paymentStatus &&
          account.paymentStatus.toLowerCase() === 'subscription' &&
          ['APPSUMO_BASIC', 'APPSUMO_PROFESSIONAL', 'APPSUMO_ENTERPRISE'].includes(account.subscriptionPlanId),
        shopify: (account) =>
          // eslint-disable-next-line
          account.paymentStatus &&
          account.paymentStatus.toLowerCase() === 'subscription' &&
          ['SHOPIFY_BASIC', 'SHOPIFY_PROFESSIONAL', 'SHOPIFY_ENTERPRISE'].includes(account.subscriptionPlanId),
        'vega-trial': (account) => account.vegaIsOnTrial === true,
        vega: (account) => account.vegaIsOnTrial === false && account.vegaIsActivated === true
      }

      return this.accounts.filter((account) => filterValues.some((filter) => filterFunctions[filter](account)))
    }
  },
  beforeMount() {
    // Initialize the debounced method on component mount
    this.debouncedUpdateLocalStorage = this.debounce(this.updateLocalStorage, 300)
    const accountsFilter = localStorage.getItem('accounts-filter')
    if (accountsFilter) {
      this.accountsFilter = JSON.parse(accountsFilter)
    }
    this.onGetAllAccounts()
  },
  mounted() {
    this.isMounted = true
  },

  watch: {
    currentPage() {
      if (this.currentPage > this.maxPagesVisited) {
        this.maxPagesVisited = this.currentPage
      }
    }
  },

  methods: {
    debounce(func, wait) {
      let timeout = null
      return (...args) => {
        const later = () => {
          clearTimeout(timeout)
          func.apply(this, args)
        }
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
      }
    },
    onSearchUpdated() {
      this.accounts = []
      this.currentPage = 0
      this.isDisabledNextPage = false
      this.fetchAccounts(this.limit)
    },

    onFilterChanged(options) {
      if (options.length === 0) {
        localStorage.removeItem('accounts-filter')
      } else {
        // Only update if there's an actual change in filters
        const currentFilter = JSON.parse(localStorage.getItem('accounts-filter'))
        if (JSON.stringify(currentFilter) !== JSON.stringify(this.accountsFilter)) {
          this.debouncedUpdateLocalStorage()
        }
      }
      this.currentPage = 1
      this.$refs.table.currentx = 1
    },
    updateLocalStorage() {
      localStorage.setItem('accounts-filter', JSON.stringify(this.accountsFilter))
    },
    async impersonate(uid) {
      const vm = this
      vm.$vs.loading({ type: 'point' })
      try {
        const getUserCustomToken = vm.$functions.httpsCallable('getUserCustomToken')
        const name = vm.activeUserInfo.firstname ? `${vm.activeUserInfo.firstname} ${vm.activeUserInfo.lastname}` : vm.activeUserInfo.displayName
        const result = await getUserCustomToken({ uid, impersonate: true, loggedInUser: { uid: vm.activeUserInfo.uid, name } })
        if (result.data && result.data.status === true) {
          const token = result.data.token
          await firebase.auth().signInWithCustomToken(token)
          location.href = '/'
        } else {
          const message = vm.$i18n.t(`error.${result.data.messageCode}`)
          vm.$vs.notify({
            time: 4000,
            title: vm.$i18n.t('vue.error'),
            text: message,
            color: 'danger',
            iconPack: 'feather',
            position: 'bottom-right',
            icon: 'icon-alert-circle'
          })
          vm.$vs.loading.close()
        }
      } catch (error) {
        const message = error.message
        vm.$vs.notify({
          time: 4000,
          title: vm.$i18n.t('vue.error'),
          text: message,
          color: 'danger',
          iconPack: 'feather',
          position: 'bottom-right',
          icon: 'icon-alert-circle'
        })
        vm.$vs.loading.close()
      }
    },
    widgetLastUpdated(widget) {
      if (widget && widget.date) {
        return `${dayjs(widget.date.toDate()).fromNow()} (${widget.name})`
      }
      return ''
    },

    async getTotalVisitorsPreviousMonth(account) {
      let totalVisitors = 0
      const { year, month, monthNumber } = this.getPreviousMonthYear()

      const daysInMonth = new Date(year, monthNumber + 1, 0).getDate() // Get the number of days in the previous month

      try {
        const docRef = await this.$db
          .collection('visitors-count')
          .doc(account.id)
          .collection('year')
          .doc(year.toString())
          .collection('month')
          .doc(month.toString())
          .get()

        if (docRef && docRef.data()) {
          totalVisitors = docRef.data().totalVisitors || 0
        }
      } catch (error) {
        // eslint-disable-next-line
        console.error('Error fetching total visitors:', error)
      }

      const averageVisitors = totalVisitors / daysInMonth
      return { totalVisitors, averageVisitors }
    },
    async getTotalVisitorsCurrentMonth(account) {
      let totalVisitors = 0
      const { year, month, monthNumber } = this.getCurrentMonthYear()

      const daysInMonth = new Date(year, monthNumber + 1, 0).getDate() // Get the number of days in the current month
      try {
        const docRef = await this.$db
          .collection('visitors-count')
          .doc(account.id)
          .collection('year')
          .doc(year.toString())
          .collection('month')
          .doc(month.toString())
          .get()

        if (docRef && docRef.data()) {
          totalVisitors = docRef.data().totalVisitors || 0
        }
      } catch (error) {
        // eslint-disable-next-line
        console.error('Error fetching total visitors:', error)
      }
      const averageVisitors = totalVisitors / daysInMonth
      return { totalVisitors, averageVisitors }
    },

    async getTotalVisitors(account) {
      const previousMonthData = await this.getTotalVisitorsPreviousMonth(account)
      const currentMonthData = await this.getTotalVisitorsCurrentMonth(account)

      return {
        previousMonth: {
          totalVisitors: previousMonthData.totalVisitors,
          averageVisitors: previousMonthData.averageVisitors
        },
        currentMonth: {
          totalVisitors: currentMonthData.totalVisitors,
          averageVisitors: currentMonthData.averageVisitors
        }
      }
    },

    getPreviousMonthYear() {
      const date = new Date()
      let year = date.getFullYear()
      let monthNumber = date.getMonth() - 1

      if (monthNumber < 0) {
        monthNumber = 11 // December
        year -= 1 // Previous year
      }

      const month = new Date(year, monthNumber, 1).toLocaleString('default', { month: 'short' }).toUpperCase()

      return { year, month, monthNumber }
    },
    getCurrentMonthYear() {
      const date = new Date()
      date.setMonth(date.getMonth())
      const year = date.getFullYear()
      const month = date.toLocaleString('default', { month: 'short' }).toUpperCase()
      const monthNumber = date.getMonth()
      return { year, month, monthNumber }
    },
    async getTotalAnalysedVisitorsPreviousMonth(account) {
      let totalVisitors = 0
      const { year, month, monthNumber } = this.getPreviousMonthYear()
      const daysInMonth = new Date(year, monthNumber + 1, 0).getDate() // Get the number of days in the previous month

      try {
        const docRef = await this.$db
          .collection('vega-visitors-count')
          .doc(account.id)
          .collection('year')
          .doc(year.toString())
          .collection('month')
          .doc(month.toString())
          .get()

        if (docRef && docRef.data()) {
          totalVisitors = docRef.data().totalVisitors || 0
        }
      } catch (error) {
        // eslint-disable-next-line
        console.error('Error fetching total visitors:', error)
      }

      const averageVisitors = totalVisitors / daysInMonth
      return { totalVisitors, averageVisitors }
    },
    async getTotalAnalysedVisitorsCurrentMonth(account) {
      let totalVisitors = 0
      const { year, month, monthNumber } = this.getCurrentMonthYear()
      const daysInMonth = new Date(year, monthNumber + 1, 0).getDate() // Get the number of days in the current month
      try {
        const docRef = await this.$db
          .collection('vega-visitors-count')
          .doc(account.id)
          .collection('year')
          .doc(year.toString())
          .collection('month')
          .doc(month.toString())
          .get()

        if (docRef && docRef.data()) {
          totalVisitors = docRef.data().totalVisitors || 0
        }
      } catch (error) {
        // eslint-disable-next-line
        console.error('Error fetching total visitors:', error)
      }
      const averageVisitors = totalVisitors / daysInMonth
      return { totalVisitors, averageVisitors }
    },

    async getTotalAnalysedVisitors(account) {
      const previousMonthData = await this.getTotalAnalysedVisitorsPreviousMonth(account)
      const currentMonthData = await this.getTotalAnalysedVisitorsCurrentMonth(account)

      return {
        previousMonth: {
          totalVisitors: previousMonthData.totalVisitors,
          averageVisitors: previousMonthData.averageVisitors
        },
        currentMonth: {
          totalVisitors: currentMonthData.totalVisitors,
          averageVisitors: currentMonthData.averageVisitors
        }
      }
    },
    getExpireAfterDays(account) {
      const expiryDate = account.expiryDate.toDate()
      // Convert the expiry date to a dayjs object
      const expiryDayjs = dayjs(expiryDate)

      // Get the current date
      const currentDate = dayjs()

      // Calculate the difference in days
      const daysRemaining = expiryDayjs.diff(currentDate, 'day')

      return daysRemaining
    },
    getAccountStatus(account) {
      let status = ''
      if (!account) return status

      if (['open', 'paid', 'subscription'].includes(account.paymentStatus)) {
        status = account.subscriptionPlanId || ''
      } else {
        status = account.paymentStatus
      }

      if (account.vegaIsActivated && !account.vegaIsOnTrial) {
        status += ' +(VEGA Subscription)'
      }

      return status
    },
    async fetchAccounts(limit, startAfter = null, direction = 'next') {
      this.$vs.loading()
      try {
        const allAccounts = []
        let baseQuery = this.$db.collection('company-lite').orderBy('created', 'desc').limit(limit)

        if (this.searchFilter) {
          switch (this.searchFilter.value) {
            case 'on-trial':
              baseQuery = baseQuery.where('paymentStatus', '==', 'on-trial')
              break

            case 'free':
              baseQuery = baseQuery.where('planType', '==', 'free')
              break

            case 'letsconnect':
              baseQuery = baseQuery.where('paymentStatus', '==', 'subscription')
              baseQuery = baseQuery.where('subscriptionPlanId', 'in', [
                'M_BASIC_MONTHLY',
                'M_BASIC_ANNUAL',
                'M_PROFESSIONAL_MONTHLY',
                'M_PROFESSIONAL_ANNUAL',
                'M_ENTERPRISE_MONTHLY',
                'M_ENTERPRISE_ANNUAL'
              ])
              break

            case 'appsumo':
              baseQuery = baseQuery.where('paymentStatus', '==', 'subscription')
              baseQuery = baseQuery.where('subscriptionPlanId', 'in', ['APPSUMO_BASIC', 'APPSUMO_PROFESSIONAL', 'APPSUMO_ENTERPRISE'])
              break

            case 'shopify':
              baseQuery = baseQuery.where('paymentStatus', '==', 'subscription')
              baseQuery = baseQuery.where('subscriptionPlanId', 'in', ['SHOPIFY_BASIC', 'SHOPIFY_PROFESSIONAL', 'SHOPIFY_ENTERPRISE'])
              break

            case 'vega':
              baseQuery = baseQuery.where('vegaIsActivated', '==', true)
              break

            default:
              break
          }
        }

        if (startAfter) {
          // eslint-disable-next-line
          console.log(`Fetching ${direction} set of results starting after: ${startAfter.id}`)
          if (direction === 'next') {
            baseQuery = baseQuery.startAfter(startAfter)
          } else if (direction === 'previous') {
            baseQuery = baseQuery.endBefore(startAfter).limitToLast(limit)
          }
        }

        const querySnapshot = await baseQuery.get()
        const mergedResults = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))

        const visitorPromises = mergedResults.map((account) => this.getTotalAnalysedVisitors({ id: account.id }))
        const totalVisitorsPromises = mergedResults.map((account) => this.getTotalVisitors({ id: account.id }))
        const measuredVisitorsPromises = mergedResults.map((account) => this.getMeasuredVisitors({ id: account.id }))

        const visitorsResults = await Promise.all(visitorPromises)
        const totalVisitorsResults = await Promise.all(totalVisitorsPromises)
        const measuredVisitorsResults = await Promise.all(measuredVisitorsPromises)

        const existingAccountIds = new Set(this.accounts.map((account) => account.id))

        mergedResults.forEach((account, index) => {
          const _company = {
            id: account.id,
            name: account.name,
            created: dayjs(account.created.seconds * 1000).format('LLL'),
            createdBy: account.createdBy,
            currentInvoiceId: account.currentInvoiceId,
            mollieSubscriptionId: account.mollieSubscriptionId,
            subscriptionId: account.subscriptionId,
            subscriptionPlanId: account.subscriptionPlanId,
            isShopifyAccount: account.isShopifyAccount,
            isAppSumoAccount: account.isAppSumoAccount,
            planType: account.planType,
            paymentStatus: account.paymentStatus,
            vegaIsOnTrial: account.vegaIsOnTrial,
            vegaIsActivated: account.vegaIsActivated,
            expiryDate: account.expiryDate,
            lastWidgetUpdated: this.widgetLastUpdated(account.lastWidgetUpdated),
            visitors: visitorsResults[index],
            totalVisitors: totalVisitorsResults[index],
            measuredVisitors: measuredVisitorsResults[index]
          }
          if (_company.subscriptionPlanId && _company.subscriptionPlanId.indexOf('FREE') > -1) {
            _company.paymentStatus = 'FREE'
          }
          if (!existingAccountIds.has(_company.id)) {
            allAccounts.push(_company)
            existingAccountIds.add(_company.id)
          }
        })

        if (mergedResults.length > 0) {
          this.lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]
          this.firstVisible = querySnapshot.docs[0]
          this.accounts = [...this.accounts, ...allAccounts]
          this.pages.push({ first: this.firstVisible, last: this.lastVisible })

          this.currentPage += 1
          this.$refs.table.currentx = this.currentPage
        } else {
          this.isDisabledNextPage = true
          // eslint-disable-next-line
          console.log('No more results to fetch.')
        }
      } catch (error) {
        this.isDisabledNextPage = true
        // eslint-disable-next-line
        console.error('Error fetching accounts:', error.message)
      } finally {
        this.$vs.loading.close()
      }
    },

    async onGetAllAccounts() {
      this.accounts = [] // Reset accounts before fetching
      this.pages = [] // Reset pages
      await this.fetchAccounts(this.limit)
    },
    async onNextPage() {
      const totalRecordsLoaded = this.filteredAccounts.length
      const currentItemsDisplayed = this.currentPage * this.itemsPerPage

      if (currentItemsDisplayed < totalRecordsLoaded) {
        // If not on the last page of the current set, just move to the next page
        this.currentPage += 1
        this.$refs.table.currentx = this.currentPage
      } else if (this.lastVisible) {
        // If on the last page of the current set, fetch the next set of records
        await this.fetchAccounts(this.limit, this.lastVisible, 'next')
      }
    },
    onPreviousPage() {
      if (this.currentPage > 0) {
        this.currentPage -= 1
        this.$refs.table.currentx = this.currentPage
        this.isDisabledNextPage = false
      }
    },
    async getMeasuredVisitorsPreviousMonth(account) {
      let totalVisitors = 0
      const { year, month, monthNumber } = this.getPreviousMonthYear()

      const daysInMonth = new Date(year, monthNumber + 1, 0).getDate() // Get the number of days in the previous month

      try {
        const docRef = await this.$db
          .collection('vega-measured-visitors-count')
          .doc(account.id)
          .collection('year')
          .doc(year.toString())
          .collection('month')
          .doc(month.toString())
          .get()

        if (docRef && docRef.data()) {
          totalVisitors = docRef.data().totalVisitors || 0
        }
      } catch (error) {
        // eslint-disable-next-line
        console.error('Error fetching total visitors GMVPM:', error)
      }

      const averageVisitors = totalVisitors / daysInMonth
      return { totalVisitors, averageVisitors }
    },
    async getMeasuredVisitorsCurrentMonth(account) {
      let totalVisitors = 0
      const { year, month, monthNumber } = this.getCurrentMonthYear()

      const daysInMonth = new Date(year, monthNumber + 1, 0).getDate() // Get the number of days in the current month
      try {
        const docRef = await this.$db
          .collection('vega-measured-visitors-count')
          .doc(account.id)
          .collection('year')
          .doc(year.toString())
          .collection('month')
          .doc(month.toString())
          .get()

        if (docRef && docRef.data()) {
          totalVisitors = docRef.data().totalVisitors || 0
        }
      } catch (error) {
        // eslint-disable-next-line
        console.error('Error fetching total visitors: GMVPM', error)
      }
      const averageVisitors = totalVisitors / daysInMonth
      return { totalVisitors, averageVisitors }
    },

    async getMeasuredVisitors(account) {
      const previousMonthData = await this.getMeasuredVisitorsPreviousMonth(account)
      const currentMonthData = await this.getMeasuredVisitorsCurrentMonth(account)

      return {
        previousMonth: {
          totalVisitors: previousMonthData.totalVisitors,
          averageVisitors: previousMonthData.averageVisitors
        },
        currentMonth: {
          totalVisitors: currentMonthData.totalVisitors,
          averageVisitors: currentMonthData.averageVisitors
        }
      }
    }
  }
}
</script>

<style lang="scss">
#data-list-thumb-view {
  .chip-large {
    font-size: 1.3em;
  }
  .icon-large {
    font-size: 1.5em;
  }
  .search-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 120px;
    margin-bottom: 10px;
  }

  .left-filter,
  .right-search {
    max-width: 400px;
    width: 100%;
  }

  .filter-dd {
    max-width: 400px;
  }

  .custom-tooltip .tooltiptext {
    display: block;
    width: max-content;
    background-color: rgba(var(--vs-secondary), 1) !important;
    color: #fff;
    text-align: justify;
    border-radius: 6px;
    padding: 10px;
    font-size: 0.8rem;
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    font-weight: 400;
    height: fit-content;
    white-space: nowrap;

    /* Position the tooltip */
    position: relative;
    z-index: 60000;
  }

  .custom-tooltip {
    position: relative;
    display: inline-block;
  }

  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
          &.img-container {
            // width: 1rem;
            // background: #fff;

            span {
              display: flex;
              justify-content: flex-start;
            }

            .product-img {
              height: 110px;
            }
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: capitalize;
          font-weight: 600;
          box-shadow: none !important;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
