var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "data-list-container",
      attrs: { id: "data-list-thumb-view" },
    },
    [
      _c("div", { staticClass: "search-container" }, [
        _c(
          "div",
          { staticClass: "left-filter" },
          [
            _c("v-select", {
              staticClass: "filter-dd",
              attrs: {
                placeholder: "Filter",
                multiple: "",
                options: _vm.optionsPaymentStatus,
              },
              on: { input: _vm.onFilterChanged },
              model: {
                value: _vm.accountsFilter,
                callback: function ($$v) {
                  _vm.accountsFilter = $$v
                },
                expression: "accountsFilter",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "right-search" },
          [
            _c("v-select", {
              staticClass: "filter-dd",
              attrs: { placeholder: "Search", options: _vm.searchOptions },
              on: { input: _vm.onSearchUpdated },
              model: {
                value: _vm.searchFilter,
                callback: function ($$v) {
                  _vm.searchFilter = $$v
                },
                expression: "searchFilter",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "vs-table",
        {
          ref: "table",
          attrs: {
            pagination: "",
            "max-items": _vm.itemsPerPage,
            search: "",
            data: _vm.filteredAccounts,
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (ref) {
                var data = ref.data
                return [
                  _c(
                    "tbody",
                    _vm._l(data, function (tr, indextr) {
                      return _c(
                        "vs-tr",
                        { key: indextr, attrs: { data: tr } },
                        [
                          _c("vs-td", { attrs: { data: tr.name } }, [
                            _c(
                              "div",
                              { staticClass: "flex flex-column items-start" },
                              [
                                tr.isShopifyAccount
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        [
                                          _c("shopify-icon", {
                                            attrs: { width: 60, height: 17 },
                                          }),
                                        ],
                                        1
                                      ),
                                    ])
                                  : tr.isAppSumoAccount
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        [
                                          _c("AppSumoLogoDark", {
                                            attrs: { width: 60, height: 10 },
                                          }),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(tr.name) + " - " + _vm._s(tr.id)
                                  ),
                                ]),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "vs-chip",
                                      {
                                        staticClass: "chip-large",
                                        attrs: {
                                          transparent: "",
                                          color: "success",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(tr.created) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "vs-td",
                            [
                              _vm.getAccountStatus(tr) === "on-trial"
                                ? _c(
                                    "vs-chip",
                                    {
                                      staticClass: "chip-large",
                                      attrs: {
                                        transparent: "",
                                        color: "warning",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.getAccountStatus(tr)) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                : _vm.getAccountStatus(tr).indexOf("FREE") > -1
                                ? _c(
                                    "vs-chip",
                                    {
                                      staticClass: "chip-large",
                                      attrs: {
                                        transparent: "",
                                        color: "danger",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.getAccountStatus(tr)) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "vs-chip",
                                    {
                                      staticClass: "chip-large",
                                      attrs: {
                                        transparent: "",
                                        color: "success",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.getAccountStatus(tr)) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                            ],
                            1
                          ),
                          _c(
                            "vs-td",
                            [
                              _c(
                                "vs-chip",
                                {
                                  staticClass: "chip-large",
                                  attrs: { transparent: "", color: "primary" },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.getExpireAfterDays(tr)) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "vs-td",
                            [
                              tr.lastWidgetUpdated
                                ? _c(
                                    "vs-chip",
                                    {
                                      staticClass: "chip-large",
                                      attrs: {
                                        transparent: "",
                                        color: "primary",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(tr.lastWidgetUpdated) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "vs-td",
                            [
                              _c(
                                "vs-chip",
                                {
                                  staticClass: "chip-large",
                                  attrs: { transparent: "", color: "primary" },
                                },
                                [
                                  _vm._v(
                                    "\n              (" +
                                      _vm._s(
                                        tr.visitors.previousMonth.totalVisitors
                                      ) +
                                      ") " +
                                      _vm._s(
                                        tr.visitors.currentMonth.totalVisitors
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              tr.visitors.currentMonth.averageVisitors >
                              tr.visitors.previousMonth.averageVisitors
                                ? _c(
                                    "vs-chip",
                                    {
                                      staticClass: "chip-large",
                                      attrs: {
                                        transparent: "",
                                        color: "success",
                                      },
                                    },
                                    [
                                      _c("vs-icon", {
                                        staticClass: "icon-large",
                                        attrs: { icon: "trending_up" },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              tr.visitors.currentMonth.averageVisitors <
                              tr.visitors.previousMonth.averageVisitors
                                ? _c(
                                    "vs-chip",
                                    {
                                      staticClass: "chip-large",
                                      attrs: {
                                        transparent: "",
                                        color: "danger",
                                      },
                                    },
                                    [
                                      _c("vs-icon", {
                                        staticClass: "icon-large",
                                        attrs: { icon: "trending_down" },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "vs-td",
                            [
                              _c(
                                "vs-chip",
                                {
                                  staticClass: "chip-large",
                                  attrs: { transparent: "", color: "primary" },
                                },
                                [
                                  _vm._v(
                                    "\n              (" +
                                      _vm._s(
                                        tr.measuredVisitors.previousMonth
                                          .totalVisitors
                                      ) +
                                      ") " +
                                      _vm._s(
                                        tr.measuredVisitors.currentMonth
                                          .totalVisitors
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              tr.measuredVisitors.currentMonth.averageVisitors >
                              tr.measuredVisitors.previousMonth.averageVisitors
                                ? _c(
                                    "vs-chip",
                                    {
                                      staticClass: "chip-large",
                                      attrs: {
                                        transparent: "",
                                        color: "success",
                                      },
                                    },
                                    [
                                      _c("vs-icon", {
                                        staticClass: "icon-large",
                                        attrs: { icon: "trending_up" },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              tr.measuredVisitors.currentMonth.averageVisitors <
                              tr.measuredVisitors.previousMonth.averageVisitors
                                ? _c(
                                    "vs-chip",
                                    {
                                      attrs: {
                                        transparent: "",
                                        color: "danger",
                                      },
                                    },
                                    [
                                      _c("vs-icon", {
                                        staticClass: "icon-large",
                                        attrs: { icon: "trending_down" },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "vs-td",
                            [
                              _c(
                                "vs-chip",
                                {
                                  staticClass: "chip-large",
                                  attrs: { transparent: "", color: "primary" },
                                },
                                [
                                  _vm._v(
                                    "\n              (" +
                                      _vm._s(
                                        tr.totalVisitors.previousMonth
                                          .totalVisitors
                                      ) +
                                      ") " +
                                      _vm._s(
                                        tr.totalVisitors.currentMonth
                                          .totalVisitors
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              tr.totalVisitors.currentMonth.averageVisitors >
                              tr.totalVisitors.previousMonth.averageVisitors
                                ? _c(
                                    "vs-chip",
                                    {
                                      staticClass: "chip-large",
                                      attrs: {
                                        transparent: "",
                                        color: "success",
                                      },
                                    },
                                    [
                                      _c("vs-icon", {
                                        staticClass: "icon-large",
                                        attrs: { icon: "trending_up" },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              tr.totalVisitors.currentMonth.averageVisitors <
                              tr.totalVisitors.previousMonth.averageVisitors
                                ? _c(
                                    "vs-chip",
                                    {
                                      staticClass: "chip-large",
                                      attrs: {
                                        transparent: "",
                                        color: "danger",
                                      },
                                    },
                                    [
                                      _c("vs-icon", {
                                        staticClass: "icon-large",
                                        attrs: { icon: "trending_down" },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("vs-td", { staticClass: "whitespace-no-wrap" }, [
                            _c("div", { staticClass: "flex" }, [
                              _c(
                                "div",
                                { staticClass: "ml-2" },
                                [
                                  _c(
                                    "vx-tooltip",
                                    {
                                      attrs: {
                                        text: _vm.$t("vue.impersonate"),
                                        position: "top",
                                      },
                                    },
                                    [
                                      _vm.activeUserInfo.uid !== tr.createdBy
                                        ? _c("vs-button", {
                                            attrs: {
                                              color: "primary",
                                              type: "border",
                                              size: "medium",
                                              "icon-pack": "feather",
                                              icon: "icon-user",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.impersonate(
                                                  tr.createdBy
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v
            },
            expression: "selected",
          },
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", { attrs: { "sort-key": "name" } }, [
                _vm._v("\n        " + _vm._s(_vm.$t("vue.name")) + "\n      "),
              ]),
              _c("vs-th", [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("vue.status")) + "\n      "
                ),
              ]),
              _c("vs-th", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("vue.expiryAfterDays")) +
                    "\n      "
                ),
              ]),
              _c("vs-th", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("vue.widgetLastUpdated")) +
                    "\n      "
                ),
              ]),
              _c("vs-th", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("vue.analysedVisitors")) +
                    "\n      "
                ),
              ]),
              _c("vs-th", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("vue.measuredVisitors")) +
                    "\n      "
                ),
              ]),
              _c("vs-th", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("views.home.totalWebsiteVisitors")) +
                    "\n      "
                ),
              ]),
              _c("vs-th", [_vm._v(_vm._s(_vm.$t("vue.action")))]),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "vs-button",
        {
          attrs: { disabled: _vm.currentPage === 1 },
          on: { click: _vm.onPreviousPage },
        },
        [_vm._v("Previous")]
      ),
      _c(
        "vs-button",
        {
          staticClass: "ml-2",
          attrs: { disabled: _vm.isNextPageDisabled },
          on: { click: _vm.onNextPage },
        },
        [_vm._v("Next")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }